%reset_button {
	padding: 0;
	background-color: transparent;
	border: 0;
}

%base_button {
	position: relative;
	display: inline-block;
	font-size: vw(10);
	text-transform: uppercase;
	color: var(--color-text-secondary);
	background-color: var(--color-accent-primary);

	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: vw(10);
	line-height: vw(16);
	text-align: center;

	font-weight: bold;
	padding: vw(5) vw(11);
	border-radius: vw(3);
	
	text-align: center;

	cursor: pointer;
	
	transition: background-color 0.6s ease, color 0.6s ease, opacity 0.6s ease;

	span {
		color: inherit;
	}

	&.disabled {
		opacity: 0.25;
		pointer-events: none;
	}

	&:hover {
		color: white;
		text-decoration: none;
	}
}

[class^=button] {
	@extend %base_button;
}