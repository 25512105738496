.countdown-toast {
	.wrapper {
		.countdown {
			display: grid;
			align-items: center;
			justify-items: center;
			margin-right: vw(40);

			.num,
			.circle-progress {
				grid-row: 1;
				grid-column: 1;
			}

			.num {
				width: vw(80);
				height: vw(80);
				background: var(--color-accent-primary);
				font-size: vw(40);
				line-height: 1;
				font-weight: bold;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				padding-bottom: 0.05em;
			}
		}

		.text {
			font-weight: 900;
			font-size: vw(40);
			line-height: 150%;
			letter-spacing: -0.011em;
			white-space: nowrap;
			display: flex;
			align-items: center;
			margin-right: vw(40);
		}
	}
}
