* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%title {
	font-family: var(--font-family-title);
	font-weight: normal;
	line-height: 1.3;
	margin: 0 0 0.5em 0;
}

body {
	color: var(--color-text-primary);
	font-family: var(--font-family-body);
}

p {
	margin-top: 0;
	margin-bottom: 1em;

	&:last-of-type {
		margin-bottom: 0;
	}

	& + [class^=button] {
		margin-top: vw(30);
	}
}

small {
	font-size: 60%;
}

em {
	font-style: italic;
}

strong {
	font-weight: 700;
}

sup {
	font-size: 25%;
	top: 0;
	vertical-align: super;
}

.is-inverted-colors {
	@extend %inverted_colors;
}
