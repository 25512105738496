@at-root {
	:root {
		//General variables
		--color-text-primary: #fff;
		--color-text-secondary: #fff;
		--color-background-primary: #573292;
		--color-background-secondary: #FFEBC6;
		--color-accent-primary: #FF4E02;
		--color-accent-secondary: #7ABBCD;
		--font-family-title: 'Mont', sans-serif;
		--font-family-body: 'Mont', sans-serif;
	
		--color-button-text: var(--color-text-primary);
		--color-button-border: var(--color-accent-secondary);
		--color-button-background: var(--color-button-border);
	
		--container-size: 100%;
		--container-gutter: 0px;
	
		@include media-breakpoint-down(sm) {
			--container-gutter: 0px;
		}
	}
}

%inverted_colors {
	--color-text-primary: #573292;
	--color-background-primary: #FFEBC6;
}
