.notifications-list {
	--speed: 1s;
	--list-width: 100vw;
	--list-padding: 15px;

	position: fixed;
	bottom: 0;
	left: 0;
	padding: var(--list-padding);
	width: var(--list-width);
	max-height: calc(100vh - var(--margin-top) - var(--margin-bottom));
	overflow: hidden auto;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	scroll-behavior: smooth;
	z-index: 100;

	transform-origin: top right;
	transform: scale(var(--app-scale, 1));

	.notification {
		animation: notification-in-left var(--speed) $easeOutCubic;
		
		&.out {
			animation: notification-out-left calc(var(--speed) / 2) $easeInCubic forwards;
		}
	}

	.notifications-list .notification {
		transition: transform var(--speed);
	}
}

.notification {
	position: relative;
	display: flex;
	overflow: hidden;
	cursor: pointer;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	height: 1.4em;
	font-size: 16px;

	.close {
		display: none;
	}

	.content {
		padding: 0;
	}

	.title {
		font-weight: 700;
		font-size: 1em;
		line-height: 115%;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.description {
		font-weight: 400;
		font-size: 1em;
		line-height: 1.4em;
		letter-spacing: 0.05em;

		.user,
		.streamer,
		.amount {
			color: var(--color-accent-primary);
		}
	}
}

@keyframes notification-in-left {
	0% {
		transform: translate(110%, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}

@keyframes notification-out-left {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(110%, 0);
		height: 1.4em;
	}
	100% {
		height: 0;
		transform: translate(110%, 0);
	}
}
