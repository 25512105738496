.factoid-toast {
	.wrapper {
		.factoid {
			display: flex;
			max-width: vw(1200);

			.left {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					top: vw(14);
					right: 0;
					width: vw(2);
					height: calc(100% - vw(28));
					background: rgba(#000, 0.2);
				}
			}

			.right {
				padding: 0 vw(25) vw(15);
			}
		}

		.sponsor {
			display: flex;
			margin-bottom: vw(25);
			position: relative;
			left: -vw(11);

			svg {
				width: vw(96);
				height: auto;
			}

			.sponsor-text {
				flex: 0 0 auto;
				padding-top: vw(10);
				font-size: vw(20);
				line-height: 1.34;
				font-family: 'Inter', sans-serif;

				.name {
					font-weight: bold;
					font-style: italic;
				}
			}
		}

		.image {
			position: relative;
			bottom: vw(-8);
			width: vw(232);
			height: 100%;
			clip-path: polygon(0 -200%, 100% -200%, 100% 100%, 0 100%);

			img {
				position: absolute;
				bottom: 0;
				left: 50%;
				width: vw(155);
				height: auto;
				transform: translate(-50%, 0);
			}
		}

		.icon-ctn {
			height: 0;

			svg {
				position: relative;
				transform: translate(0, -50%);
			}
		}

		.text {
			font-size: vw(32);
			line-height: 1.2;
			letter-spacing: 0;
			font-weight: bold;
		}
	}
}
