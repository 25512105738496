.page.home {
	position: relative;
	height: calc(var(--vh) * 100);

	background: #000;
	
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	z-index: 1;

	.button {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.counter {
		position: absolute;
		top: vw(20);
		left: vw(20);
		color: #fff;
		font-size: vw(24);
	}

	video {
		position: absolute;
		object-fit: contain;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}
